import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal'
import Edit02Icon from '@/components/shared/icons/Edit02Icon'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import {
  CAvatar,
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import avatar from '../../../../assets/img/avatar.jpg'
import { RootState } from '../../../reducers'
import {
  ResponseStatus,
  ResponseStatusUser,
  mapResponseStatus,
} from '../../../shared/enumeration/ResponseStatus'
import { mapRoleToString, SystemRole } from '../../../shared/enumeration/role'
import { IUser } from '../../../shared/model/user.model'
import { createIndexes } from '../../../shared/utils/ultils'
import { AppDispatch } from '../../../store'
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter'
import FilterIcon from '../../shared/icons/FilterIcon'
import PlusIcon from '../../shared/icons/PlusIcon'
import SearchIcon from '../../shared/icons/SearchIcon'
import TrashIcon from '../../shared/icons/TrashIcon'
import UserUpdate from './UserUpdate'
import { getEntities, removeEntity, updateEntity } from './usersManagement.api'
import {
  fetching,
  resetEntity,
  setFilterState,
  userSelectors,
} from './usersManagement.reducer'

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  // {
  //   key: 'avatar',
  //   label: '',
  //   sorter: false,
  // },
  // {
  //   key: 'fullName',
  //   label: 'Họ và tên',
  //   sorter: false,
  // },
  {
    key: 'username',
    label: 'Tài khoản - mã mời',
    sorter: false,
  },
  {
    key: 'role',
    label: 'Loại tài khoản',
    sorter: false,
  },
  {
    key: 'agentId',
    label: 'Mã đại lý',
    sorter: false,
  },
  {
    key: 'vip',
    label: 'Vip',
    sorter: false,
  },
  {
    key: 'ip',
    label: 'IP',
    sorter: false,
  },
  {
    key: 'state',
    label: 'Trạng thái',
    sorter: false,
  },
  {
    key: 'money',
    label: 'Số dư',
    sorter: false,
  },
  // {
  //   key: 'addMoney',
  //   label: 'Nạp',
  //   sorter: false,
  // },
  // {
  //   key: 'subMoney',
  //   label: 'Rút',
  //   sorter: false,
  // },
  {
    key: 'bank',
    label: 'Ngân hàng',
    sorter: false,
    _props: { className: 'text-center' },
  },
  {
    key: 'status',
    label: 'Tình trạng',
    sorter: false,
    _props: { width: '20%' },
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
]

interface IIndexUser extends IUser {
  index: number
}

const AgentManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { initialState } = useSelector((state: RootState) => state.usersReducer)
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState
  const [createUser, setCreateUser] = useState<boolean>(false)
  const [updateUser, setUpdateUser] = useState<IUser | null>(null)

  const handleCreateUser = () => {
    setCreateUser(true)
  }

  const handleUpdateUser = (user: IUser) => () => {
    setUpdateUser(user)
  }

  const handleCancelUpdateUser = (update?: boolean) => {
    setUpdateUser(null)
  }

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0)
      dispatch(
        setFilterState({ ...filterState, page: page, role: SystemRole.AGENT }),
      )
    }
  }

  const users = useSelector(userSelectors.selectAll)
  const indexedUser = createIndexes(users, filterState)

  const handleUpdateStatus = (user: IUser) => () => {
    const status =
      user.status === ResponseStatusUser.ACTIVE
        ? ResponseStatusUser.INACTIVE
        : ResponseStatusUser.ACTIVE
    dispatch(fetching())
    dispatch(updateEntity({ ...user, status }))
  }

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật trạng thái thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching())
      dispatch(getEntities({ ...filterState, role: SystemRole.AGENT }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess])

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá ngườin dùng thành công')
      dispatch(resetEntity())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess])

  const [chosenDelete, setChosenDelete] = useState<IUser | null>(null)

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id))
      setChosenDelete(null)
    }
  }

  const handleCancelDelete = () => {
    setChosenDelete(null)
  }

  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    dispatch(getEntities({ ...filterState, keyword, page: 1, role: SystemRole.AGENT }));
  }

  const onReset = () => {
    dispatch(getEntities({ ...filterState, keyword: '', page: 1, role: SystemRole.AGENT }));
    setKeyword('');
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className="d-flex">
            <CFormInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ID hoặc Tên đăng nhập"
            />
            <CButton
              style={{ height: 42, whiteSpace: 'nowrap' }}
              className="ms-2 btn-custom btn-sm text-white"
              onClick={onSearch}
            >
              Tìm kiếm
            </CButton>
            <CButton style={{ height: 42, whiteSpace: 'nowrap'}} className="ms-2 btn-custom btn-sm text-white bg-danger" onClick={onReset}>
              Đặt lại
            </CButton>
          </div>
          {/* <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Tạo tài khoản mới
            </CButton>
          </div> */}
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput
                placeholder="Tìm kiếm theo tên"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            loading={loading}
            columns={columns}
            items={indexedUser}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              role: ({ index, ...value }: IIndexUser) => (
                <td>{mapRoleToString[value.role]}</td>
              ),
              status: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatusUser.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                  </div>
                </td>
              ),
              state: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <span
                      className={
                        value.chatStatus == 0
                          ? 'text-danger fw-bold'
                          : 'text-success fw-bold'
                      }
                    >
                      {value.chatStatus == 0 ? 'Ngoại tuyến' : 'Trực tuyến'}
                    </span>
                  </div>
                </td>
              ),
              money: ({ index, ...value }: IIndexUser) => (
                <td>
                  {value.money
                    ? new Intl.NumberFormat('vi-VN').format(value?.money || 0)
                    : ''}
                </td>
              ),
              addMoney: ({ index, ...value }: IIndexUser) => (
                <td>
                  {value.addMoney
                    ? new Intl.NumberFormat('vi-VN').format(
                        value?.addMoney || 0,
                      )
                    : ''}
                </td>
              ),
              subMoney: ({ index, ...value }: IIndexUser) => (
                <td>
                  {value.subMoney
                    ? new Intl.NumberFormat('vi-VN').format(
                        value?.subMoney || 0,
                      )
                    : ''}
                </td>
              ),
              bank: ({ index, ...value }: IIndexUser) => {
                return (
                  <td className="text-center">
                    <div
                      className={'d-flex justify-content-between flex-column'}
                    >
                      <div className="mb-2 fw-bold">{value?.bank?.name}</div>
                      <div className="mb-2">{value?.cardNumber}</div>
                      <div>{value?.cardName}</div>
                    </div>
                  </td>
                )
              },
              // role: (value: IUser) => <td>{mapRoleToString[value.role]}</td>,
              actions: ({ index, ...value }: IIndexUser) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateUser(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {value.status !== ResponseStatusUser.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ''
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createUser ? (
          <UserUpdate visible={createUser} setVisible={setCreateUser} />
        ) : (
          ''
        )}
        {updateUser ? (
          <UserUpdate
            visible={Boolean(updateUser)}
            userObj={updateUser}
            setVisible={handleCancelUpdateUser}
          />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá tài khoản khỏi hệ thống"
          content={
            <>
              Xác nhận xoá tài khoản{' '}
              <span className="high-light">{chosenDelete.username}</span> khỏi
              hệ thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  )
}

export default AgentManagement
